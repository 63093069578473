import { Box } from '@mui/material';
import React from 'react';
import ASTITitle from '../../labels/ASTITitle';
import ASTIText from '../../labels/ASTIText';

export default function Teachers() {
  return (
    <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    gap={4}>
      <ASTITitle text="Teachers" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        gap={2}
      >
        <ASTIText text="Rob AndristPlourde (Boom Chicago)" />
        <ASTIText text="MC Hammersmith" />
        <ASTIText text="Douglas Widick, RJ Williams, Ralf Jean Pierre (NorthCoast NYC)" />
        <ASTIText text="Raquel Palmas (NorthCoast NYC/Boom Chicago)" />
        <ASTIText text="Ryan Millar" />
        <ASTIText text="Lee Apsey (Slice of Rhyme)" />
        <ASTIText text="Marco Meurs (Everywhere)" />
        <ASTIText text="Larry Dorsey " />'
        <ASTIText text="Contact and/or book us !! Follow us and send us a DM on Instagram -> @astir.oids" />
      </Box>
    </Box>
  );
}
