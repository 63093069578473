import React, { useState } from 'react';
import { Box } from '@mui/material';

import ASTINavBar from '../navbar/ASTINavbar';
import ASTIHeader from '../labels/ASTIHeader';
import ASTIDashboardBody from './ASTIDashboardBody';
import AstiroidsLogo from '../../assets/images/astiroids_logo.png';

export default function ASTIDashboard() {
  const [section, setSection] = useState('default');

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py={4}
        width="100%"
        position="fixed"
        top={0}
        // Kevin Code
        sx={{ backgroundColor: 'black', borderBottom: '1px solid gray', zIndex: 1200, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', }} // TODO boxShadow?
        // Chat GPT Code
        // sx={{
        //  backgroundColor: 'black',
        //  borderBottom: '1px solid gray',
        //  zIndex: 1200, // Ensure header stays above content
        //  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Add shadow
        //}}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <img src={AstiroidsLogo} alt="astiroids_logo" width="5%" />
          <ASTIHeader text="ASTIRoids" />
        </Box>
        <ASTINavBar section={section} setSection={setSection} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        
        
        //Below is Kevin code for padding

        // pt={90}
        // pb={8}
        
        // Below ChatGPT code proposition for the padding

        sx={{
          paddingTop: { xs: '350px', sm: '150px' }, // Highlight: Extra top padding for smaller screens
          // paddingX: { xs: '0px', sm: '0px' }, // Add side padding for small screens
          paddingBottom: { xs: '8px', sm: '8px' }, // Bottom padding for mobile
        }}
      >
        <ASTIDashboardBody section={section} />
      </Box>
    </Box>
  );
}
