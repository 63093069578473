import { Box } from '@mui/material';
import React from 'react';
import ASTITitle from '../../labels/ASTITitle';
import ASTIText from '../../labels/ASTIText';

export default function Members() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      gap={4}
    >
      <ASTIText text="Contact and/or book us !! Follow us and send us a DM on Instagram -> @astir.oids" />
      
      <ASTITitle text="Musicians" />

      <ASTIText text="Isaac Simon aka MC BeardSweats on the Cajon - @sublimedumbness" />
      <ASTIText text="Aymeric Strauss on the Beatbox - @aymericstrauss" />
      <ASTIText text="Nikesh Katwa aka DubDedko as the DJ - @dub.dedko" />
                
      <ASTITitle text="Active Freestylers" />
      <ASTIText text="by order of Appearance" />
      
      <ASTIText text="Manuel Sans Persijn aka ChebManu - @chebmanu" />
      <ASTIText text="Ollie Mason aka RaviOllie - @olliemason_writer" />
      <ASTIText text="Marco Meurs aka QMarCode - marcomeurs.nl" />
      <ASTIText text="Thomas de Boer aka T-Raps" />
      <ASTIText text="Kat aka DJKittyKat - @stonk06" />
      <ASTIText text="Mark Pichel aka M. Mumble - @the_cultural_errorist" />
      <ASTIText text="Mateus Villas Boas aka YoGuyMat - @matvillasboas" />
      <ASTIText text="Benjamin McDiarmid aka MC Maybe - @whoajamin" />
      <ASTIText text="Coen Rens aka The Vegan Vampire - @coenrens" />
      <ASTIText text="Chris Spence aka The Suspence - @thesuspenceiskillingyou" />
      <ASTIText text="Deniz Aladagli aka Aladeniz - @aladeniz" />
      <ASTIText text="Davide Dispenza aka Double D - @davide.dispenza" />
      <ASTIText text="Supriya Rakesh - @ssupriyarakesh" />
      <ASTIText text="Nadine aka Muminem - @nad_kmg" />
      <ASTIText text="Florian Gerritsen aka Cascade - @okivoz" />
      <ASTIText text="Cate Mackenzie aka Cate-a-lyst - @catezh" />
      <ASTIText text="Mik Grosheide aka MikyWay - @mik.grosheide" />
      <ASTIText text="Inge Bevers aka Inglewood - @inge_bevers" />
      <ASTIText text="Niharika Iyengar aka The Sexy Stuntwoman - @niharika.iyengar" />
     
      <ASTITitle text="Alumni" />
      <ASTITitle text="by order of Disappearance" />
     
      <ASTIText text="Mash Muravina aka DrMishMash - @mash_muravina" />
      <ASTIText text="Sue Narantsoa aka SueLaLoose - @nasiantsoa" />
      <ASTIText text="Thomas Bosman aka Thomas The Rap Engine - @thomasbosmania" />
     
    </Box>
  );
}
