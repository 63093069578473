import React from 'react';
import { Box } from '@mui/material';

import ASTITitle from '../../labels/ASTITitle';
import ASTIText from '../../labels/ASTIText';

export default function BigBang() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      gap={4}
    >
      <ASTITitle text="BigBang" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="80%"
        gap={2}
      >
        <ASTIText text="" />
        <ASTIText text="ASTIRoids started in September 2022 as a bunch of Amsterdam musical improvisers who were looking to debut and improve their improvised rapping skills together." />
        <ASTIText text="They started in ChebManu's living room and soon expanded into bi-weekly Jam Sessions open to all, which often include a dozen participants." />
        <ASTIText text="ASTIRoids is a twist on the word Asteroids and the acronym ASTIR which stands for the Amsterdam School of Theatrically Improvised Rappers." />
        <ASTIText text="ASTIRoids are their members and the ambition is to have fun and go on stage." />
        <ASTIText text="Contact and/or book us !! Follow us and send us a DM on Instagram -> @astir.oids" />

      </Box>
    </Box>
  );
}
